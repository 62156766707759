.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-self: stretch;
}

.entering {
  animation: slide-in-fwd-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.exiting {
  animation: slide-out-bck-center 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1s both;
}
@keyframes slide-out-bck-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(-1100px);
    transform: translateZ(-1100px);
    opacity: 0;
  }
}

.entered {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 500ms;
}
.exited {
  opacity: 0;
}

.catalogCover {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  background-color: var(--colorGray2);
  overflow: hidden;
  position: relative;
  z-index: 0;
  cursor: pointer;
  border-radius: 0.25rem;
}

.img {
  width: 100%;
  max-height: 100%;
}

.previewBadge {
  position: absolute;
  z-index: 2;
  top: 6px;
  right: 6px;
}

.sellerContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 0.75rem;
}

.displayName {
  composes: ui14SemiBold from 'styles/typeography.css';
  color: var(--colorGray11);
  display: -webkit-box;
  word-wrap: break-word;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  margin-bottom: 0.25rem;
}

.sellerMeta {
  display: flex;
  align-items: center;
  composes: ui12Medium from 'styles/typeography.css';
  color: var(--colorGray6);
  margin: 0;
}

.sellerMetaIcon {
  color: var(--colorGray5);
  margin-right: 0.25rem;
}

.badges {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.75rem;
}

.badge {
  margin: 0 0.25rem 0.25rem 0;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
}

.requestPricingButton {
  margin-top: 16px;
}
