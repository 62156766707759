.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 4rem 0 2rem 0;
}

.branding {
  margin-bottom: 2rem;
}

.heading {
  composes: brand40 from 'styles/typeography.css';
  text-align: center;
  margin: 0 1.5rem 1.5rem 1.5rem;
}

.subhead {
  composes: ui14Medium from 'styles/typeography.css';
  text-align: center;
  color: var(--colorGray8);
  margin-bottom: 2rem;
}
