.modalContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
  max-width: 1269px;
  width: 100%;
  border-radius: 8px;
}

/* Hide modal scrollbar */
.modalContainer::-webkit-scrollbar {
  width: 0 !important;
}

.bannerImage {
  position: relative;
  overflow: hidden;
  width: inherit;
  padding-top: 27%;
}
.bannerImage > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

/* Seller Details content */
.sellerDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  padding: 24px;
  width: inherit;
}
.sellerDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: inherit;
}
.leftInfo {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
}
.displayName {
  composes: ui32SemiBold from 'styles/typeography.css';
  margin-bottom: 4px;
}
.logoImage {
  width: 56px;
  height: 56px;
  border-radius: 48px;
  object-fit: cover;
}
.nameLocation {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.sellerMeta {
  display: flex;
  align-items: center;
  composes: ui14Regular from 'styles/typeography.css';
  color: var(--colorGray8);
  margin-bottom: 0.125rem;
}
.sellerMetaIcon {
  color: var(--colorGray6);
  margin-right: 0.5rem;
}
.pricingRequestButton {
  white-space: nowrap;
  overflow: inherit;
}
.description {
  composes: ui14Regular from 'styles/typeography.css';
  color: var(--colorGray11);
  margin-bottom: 0;
}
.topBrandValuesTitle {
  composes: ui12Medium from 'styles/typeography.css';
  color: var(--colorGray7);
  margin-bottom: 8px;
}

/* Product search and sorting content */
.productSearchAndSortContainer {
  /* display: flex; */
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  padding: 0 24px;
  width: inherit;
}

/* Product grid content */
.productGridContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 48px;
  padding: 24px;
  width: inherit;
}
.progressSpinner {
  width: 50px;
  height: 550px;
  margin: auto;
}
.progressSpinnerMobile {
  width: 50px;
  height: 248px;
  margin: auto;
}
.previewProductItem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  width: 203px;
}
.productImage {
  width: 198px;
  height: 198px;
  border-radius: 8px;
  object-fit: cover;
}
.productName {
  composes: ui12Medium from 'styles/typeography.css';
  color: var(--colorBlack2);
  margin-bottom: 0;
}
.noResultsFound {
  margin-top: 24px;
  width: inherit;
}

/* Pagination styles */
.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: inherit;
  padding: 16px 0;
}

.swiperContainer {
  height: 248px;
}
.swiperSlide {
  width: unset;
}

.closeModalButton {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 10;
}

.badges {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.75rem;
}
.badge {
  margin: 0 0.25rem 0.25rem 0;
}

/* max-width: 1439px */
@media (--viewportDesktopMax) {
  .modalContainer {
    max-width: 984px;
  }
}

/* max-width: 1023px */
@media (--viewportTabletMax) {
  .modalContainer {
    max-width: 724px;
  }
  .productGridContainer {
    gap: 32px;
  }
}

/* max-width: 767px */
@media (--viewportPhoneMax) {
  .modalContainer {
    max-width: 488px;
  }
}

/* max-width: 480px */
@media (--viewportPhoneMin) {
  .modalContainer {
    max-width: 382px;
  }
  .sellerDetails {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
}
