.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  padding: 0 20px 50px 20px;
  text-align: center;
}

.header {
  composes: ui20SemiBold from 'styles/typeography.css';
  margin-top: 38px;
  margin-bottom: 8px;
}

.subtitle {
  composes: ui14Regular from 'styles/typeography.css';
  color: var(--colorGray7);
  margin-bottom: 0;
}
