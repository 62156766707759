.form {
  display: flex;
  flex-grow: 1;
}

.searchInput {
  z-index: 1;
  height: 2rem;
  /* width: -webkit-fill-available; */
  padding: 0.5rem 0rem 0.5rem 0.75rem !important;
  font-size: 1rem; /* 16p font size prevent zooming on focus for iOS devices*/
  border-color: var(--colorTan5) !important;
  border-radius: 2px !important;
  background-color: white;
}

.searchInput::placeholder {
  color: var(--colorGray5) !important;
}

.searchIcon {
  margin-top: 7px;
}

.searchIconButton {
  padding: 0;
  z-index: 2;
  height: 2rem;
  margin-left: -25px;
  background-color: white;
  border: 1px solid var(--colorGray);
  border-left-width: 0rem;
  border-right-width: 0rem;
  border-color: var(--colorGray) !important;
}

.inputTextForMobile {
  display: block;
}

.inputTextForTablet {
  display: none;
}

@media (--viewportTabletLandscapeMin) {
  .searchInput {
    font-size: 0.875rem; /* 14px */
  }
  .inputTextForMobile {
    display: none;
  }
  .inputTextForTablet {
    display: block;
  }
}
