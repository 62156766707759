/* @keyframes duration | easing-function | delay | iteration-count | direction | fill-mode | play-state | name */

.star {
  fill: #fbfaf9;
  /* animation-delay: 250ms; */
  animation-duration: 1800ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

/* ONE */

.one {
  stroke: #2562d6;
  animation-name: one;
}

@keyframes one {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(-27px, 12px);
  }
  66% {
    transform: translate(3px, 26px);
  }
}

/* TWO */

.two {
  stroke: #391284;
  animation-name: two;
}

@keyframes two {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(19px, 13px);
  }
  66% {
    transform: translate(23px, -12px);
  }
}

/* THREE */

.three {
  stroke: #2f38ab;
  animation-name: three;
}

@keyframes three {
  0% {
    transform: translate(0, 0);
  }

  33% {
    transform: translate(8px, -25px);
  }

  66% {
    transform: translate(-18px, -13px);
  }
}
